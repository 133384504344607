<template>
  <div class="header">
    <div class="webCont">
      <img src="static/lvya/logo2.png" alt="" class="logo img1"/>
      <img src="static/lvya/logo.png" alt="" class="logo img2"/>
      <div class="menuBox">
        <div class="a" :class="curRouterName == 'home' ? 'on' : ''" @click="toLink('/home')">首页<span></span></div>
        <div class="a product" :class="cpzxArr.includes(curRouterName) ? 'on' : ''">产品中心<span></span></div>
        <div class="a jjfa" :class="curRouterName == 'solution' ? 'on' : ''" @click="toLink('/solution')">解决方案<span></span></div>
        <div class="a" :class="curRouterName == 'aboutUs' ? 'on' : ''" @click="toLink('/aboutUs')">关于我们<span></span></div>
      </div>
      <!-- <a @click="goLogin()" class="link">登录</a> -->
      <div class="btns">
        <div class="btn" @click="goLogin()">校园平台登录</div> <span></span> <div class="btn" @click="toLogin()">绿芽登录</div>
      </div>
      <div class="slider slider1">
        <div class="left">
          <div class="hasChild" @click="toLink('/schoolFzpt')" :class="['schoolFzpt', 'developmentPlanning', 'studentGrowth', 'teacherDevelopment'].includes(curRouterName) ? 'on' : ''">
            <span>内涵发展产品线</span>
            <div class="item">
              <p @click.stop="toLink('/developmentPlanning')" :class="curRouterName == 'developmentPlanning' ? 'on' : ''">发展规划</p>
              <p @click.stop="toLink('/studentGrowth')" :class="curRouterName == 'studentGrowth' ? 'on' : ''">学生成长</p>
              <p @click.stop="toLink('/teacherDevelopment')" :class="curRouterName == 'teacherDevelopment' ? 'on' : ''">教师发展</p>
            </div>
          </div>
          <div @click="toLink('/jzjx')" :class="curRouterName == 'jzjx' ? 'on' : ''">
            <span>精准教学产品线</span>
          </div>
          <div :class="curRouterName == 'zhgl' ? 'on' : ''" @click="toLink('/zhgl')">
            <span>智慧管理产品线</span>
          </div>
          <div @click="toLink('/ddmpt')" :class="curRouterName == 'ddmpt' ? 'on' : ''">
            <span>低代码平台</span>
            <!-- <div class="item">
              <p @click.stop="toLink('/ddmpt')">低代码平台</p>
              <p @click.stop="hrefUrlCjdt">场景大厅</p>
            </div> -->
          </div>
          <div class="hasChild" @click="toLink('/dataCenter')" :class="['dataCenter', 'businessCenter', 'schoolBigData'].includes(curRouterName) ? 'on' : ''">
            <span>基础平台</span>
            <div class="item" style="bottom: 0;top:auto;">
              <p @click.stop="toLink('/dataCenter')" :class="curRouterName == 'dataCenter' ? 'on' : ''">数据中台</p>
              <p @click.stop="toLink('/businessCenter')" :class="curRouterName == 'businessCenter' ? 'on' : ''">业务中台</p>
              <p @click.stop="toLink('/schoolBigData')" :class="curRouterName == 'schoolBigData' ? 'on' : ''">数据驾驶舱</p>
            </div>
          </div>
        </div>
      </div>
      <div class="slider slider2" style="left: 63%;">
        <div class="left">
          <div @click="toLink('/solution')" :class="curRouterName == 'jzjx' ? 'on' : ''">
            <span>区校一体化智慧教育</span>
          </div>
          <div @click="toLink('/nhsfz')" :class="curRouterName == 'nhsfz' ? 'on' : ''">
            <span>内涵式发展</span>
          </div>
          <div @click="toLink('/kfszhxy')" :class="curRouterName == 'kfszhxy' ? 'on' : ''">
            <span>开放式智慧校园</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cpzxArr: [
        'schoolFzpt',
        'developmentPlanning',
        'studentGrowth',
        'teacherDevelopment',
        'jzjx',
        'zhgl',
        'ddmpt',
        'dataCenter',
        'businessCenter',
        'schoolBigData'
      ],
      curRouterName: ''
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        this.curRouterName = val.path.slice(1, val.length)
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    $('.menuBox .product, .header .slider1').hover(function() {
      $('.header .slider1').stop().slideDown()
    }, function() {
      $('.header .slider1').stop().slideUp()
    })
    $('.menuBox .jjfa, .header .slider2').hover(function() {
      $('.header .slider2').stop().slideDown()
    }, function() {
      $('.header .slider2').stop().slideUp()
    })
    $(window).scroll(function() {
      if ($(window).scrollTop() > 70) {
        $('.header').addClass('on')
      } else {
        $('.header').removeClass('on')
      }
    })
  },
  methods: {
    toLink(path) {
      this.$router.push({
        path
      })
    },
    hrefUrlCjdt() {
      window.open('https://eos.lvya.org/admin/#/publicSceneHall')
    },
    goLogin() {
      window.open('http://eos.lvya.org/', '_blank')
    },
    toLogin() {
      window.open('http://lvya.lvya.org/webs/th/login.do', '_blank')
    }
  }
}
</script>
<style lang="less">
  .header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid rgba(255,255,255,0.37);
    transition: all 0.5s;
    z-index: 100;
    &:hover, &.on{
      background: #FFFFFF;
      box-shadow: 0px 14px 21px 0px rgba(0,82,48,0.0500);
      transition: all 0.5s;
      .link{
        color: #1BB876;
        border: 1px solid #1BB876;
      }
      .menuBox{
        color: #222;
        .a.on{
          color: #1BB876;
        }
      }
      .logo{
        &.img1{
          display: none;
        }
        &.img2{
          display: block;
        }
      }
      .btns{
        color: #1BB876;
        span{
          background: #1BB876;
        }
      }
    }
    .webCont{
      position: relative;
    }
    .logo{
      position: absolute;
      left: 0;
      top: 13px;
      &.img1{
        display: block;
      }
      &.img2{
        display: none;
      }
    }
    .menuBox{
      position: relative;
      margin: 0 auto;
      line-height: 70px;
      color: #FFFFFF;
      width: 500px;
      font-size: 16px;
      font-weight: 300;
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .a{
        position: relative;
        font-size: 16px;
        &:hover{
          color: #1BB876;
        }
        &.on{
          // color: #1BB876;
          span{
            display: block;
            width: 35px;
            height: 3px;
            background: #FFFFFF;
            position: absolute;
            left: 50%;
            bottom: 0;
            margin-left: -17.5px;
          }
        }
      }
    }

    .slider{
      position: absolute;
      top: 67px;
      left: 50%;
      width: 180px;
      margin-left: -184px;
      background: #FFFFFF;
      // border: 1px solid #DEDEDE;
      box-shadow: 0px 27px 32px 0px rgba(30,30,30,0.2200);
      border-radius: 4px;
      display: none;
      .left{
        float: left;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        z-index: 10;
        // border-right: 1px solid #DEDEDE;
        >div{
          position: relative;
          padding: 0 17px;
          box-sizing: border-box;
          color: #5D5D5D;
          span{
            font-size: 15px;
            padding: 15px 0;
            border-bottom: 1px solid #DEDEDE;
            display: block;
          }
          cursor: pointer;
          &:nth-last-of-type(1){
            border: none;
          }
          &.hasChild{
            background: url(../../../../public/static/lvya/jt1.png) no-repeat 150px center;
            background-size: 12px;
            &:hover{
              background: url(../../../../public/static/lvya/jt.png) no-repeat 150px center;
              background-size: 12px;
            }
          }
          &:hover{
            font-weight: bold;
            color: #1BB876;
            // border-top: 1px solid #1BB876;
            // border-bottom: 1px solid #1BB876;
            // border-left: 2px solid #1BB876;
          }
          &:hover{
            .item{
              display: block;
            }
          }
        }
        .item{
          position: absolute;
          left: 98%;
          top: 0;
          // height: 100%;
          background: #FFFFFF;
          display: none;
          width: 180px;
          border-radius: 4px;
          padding: 5px 0;
          // border: 1px solid #1BB876;
          box-shadow: 0px 27px 32px 0px rgba(30,30,30,0.2200);
          font-size: 15px;
        }
      }
      .items{
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .top{
        font-size: 16px;
        font-weight: 400;
        color: #5D5D5D;
        line-height: 35px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 10px;
        cursor: pointer;
      }
      p{
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #5D5D5D;
        line-height: 32px;
        padding: 10px 0;
        cursor: pointer;
        &:hover, &.on{
          color: #1BB876;
        }
      }
    }
    .btns{
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
      display: flex;
      line-height: 70px;
      cursor: pointer;
      span{
        display: block;
        width: 1px;
        height: 14px;
        background: #fff;
        margin: 0 10px;
        position: relative;
        top: 28px;
      }
    }
  }
</style>
