<template>
  <div class="footer">
    <div class="footer-top webCont">
      <div class="left">
        <div class="item" @click="toUrl('/certificate')">
          <div class="circle circle1"></div>
          <p>证书资质</p>
        </div>
        <div class="item" @click="toUrl('/news')">
          <div class="circle circle2"></div>
          <p>新闻动态</p>
        </div>
        <div class="item" @click="toUrl('/join')">
          <div class="circle circle3"></div>
          <p>代理加盟</p>
        </div>
        <div class="item" style="cursor: text;">
          <div class="circle circle4"></div>
          <p>应用中心</p>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <img src="static/lvya/footer/ewm1.png" alt="">
          <p>金岭教育公众号</p>
        </div>
        <div class="item">
          <img src="static/lvya/footer/ewm2.png" alt="">
          <p>金岭教育视频号</p>
        </div>
        <div class="item">
          <img src="static/lvya/schoolFzpt/index/yyys3.png" alt="">
          <p>在线咨询</p>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
        <div>
            <p>
                <a href="http://beian.miit.gov.cn" target="_blank">
                    <img src="static/lvya/footer/footer-icon1.png" alt="">
                    京ICP备09013079号-5
                </a>
            </p>
            <p>
                <img src="static/lvya/footer/footer-icon2.png" alt="">
                010-64921605
            </p>
            <p>版权所有：金岭教育科技（北京）有限公司</p>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {
    toUrl(path) {
      this.$router.push({
        path
      })
    }
  }
}
</script>
<style lang="less">
  .footer{
    .footer-top{
      display: flex;
      padding: 50px 0;
      justify-content: space-between;
      .left{
        width: 660px;
        display: flex;
        justify-content: space-between;
        .item{
          text-align: center;
          cursor: pointer;
          &:hover{
            color: #0DC579;
            .circle{
              &.circle1{
                transition: all 0.5s;
                background: #0DC579 url('../../../../public/static/lvya/footer/icon-footer1-on.png') no-repeat center;
                background-size: 50%;
              }
              &.circle2{
                transition: all 0.5s;
                background: #0DC579 url('../../../../public/static/lvya/footer/icon-footer2-on.png') no-repeat center;
                background-size: 44%;
              }
              &.circle3{
                transition: all 0.5s;
                background: #0DC579 url('../../../../public/static/lvya/footer/icon-footer3-on.png') no-repeat center;
                background-size: 48%;
              }
              &.circle4{
                transition: all 0.5s;
                background: #0DC579 url('../../../../public/static/lvya/footer/icon-footer4-on.png') no-repeat center;
                background-size: 44%;
              }
            }
          }
          .circle{
            width: 120px;
            height: 120px;
            border: 1px solid #0DC579;
            border-radius: 60px;
            margin-bottom: 20px;
            &.circle1{
              background: url('../../../../public/static/lvya/footer/icon-footer1.png') no-repeat center;
              background-size: 50%;
            }
            &.circle2{
              background: url('../../../../public/static/lvya/footer/icon-footer2.png') no-repeat center;
              background-size: 44%;
            }
            &.circle3{
              background: url('../../../../public/static/lvya/footer/icon-footer3.png') no-repeat center;
              background-size: 48%;
            }
            &.circle4{
              background: url('../../../../public/static/lvya/footer/icon-footer4.png') no-repeat center;
              background-size: 44%;
            }
          }
        }
      }
      .right{
        display: flex;
        .item{
          width: 120px;
          text-align: center;
          margin-right: 50px;
          img{
            width: 120px;
            height: 120px;
            margin-bottom: 10px;
          }
        }
        .item:last-child{
          margin-right: 0;
        }
      }
    }
    .footer-bottom{
        width: 100%;
        background: #040a2a;
        color: #fff;
        text-align: center;
        line-height: 80px;
        font-size: 15px;
        div{
          display: flex;
          justify-content: center;
          font-size: 13px;
          flex-wrap: wrap;
          p {
              display: flex;
              padding: 0 15px;
              a {
                display: flex;
                color: #fff;
                text-decoration: none;
             }
             img {
                width: 26px;
                height: 26px;
                margin-top: 25px;
                margin-right: 10px;
            }
          }
        }
    }
  }
</style>
